<template>
  <div>
    <b-row class="kb-search-content-info match-height">
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imagePayers"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Admin-payers-list'})"
        >
          <h2>Lista de pagadores</h2>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageCreditEvaluation"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Admin-creditEvalutaion-list'})"
        >
          <h2>Lista de ampliación crediticia</h2>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageRequest"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Admin-fundingRequests-list'})"
        >
          <h2>Lista de solicitudes</h2>
        </b-card>
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageClients"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Admin-client-list'})"
        >
          <h2>Lista de clientes</h2>
        </b-card>
      </b-col>
      <!-- <b-col
        md="4"
        sm="6"
      >
        <b-card
          class="text-center cursor-pointer p-2"
          :img-src="imageService"
          img-top
          img-height="300"
          @click="$router.push({ name: 'Admin-service-list'})"
        >
          <h2>Lista de servicios</h2>
        </b-card>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCard,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
  },
  data() {
    return {
      imageService: require('@/assets/images/efide/ListaServicios-admin.svg'),
      imagePayers: require('@/assets/images/efide/ListaDePagadores-admin.svg'),
      imageCreditEvaluation: require('@/assets/images/efide/AmpliaciónCrediticia-adim.svg'),
      imageRequest: require('@/assets/images/efide/ListaDeSolicitudes-admin.svg'),
      imageClients: require('@/assets/images/efide/ListaDeClientes-admin.svg'),
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
  .panel-img-section {
    height: 184px;
  }
</style>
